.selectPosDiv {

    background-color: #a7b0ad96;
    border-radius: 5px;
    height: 60vh;
    width: 60vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.select-BoxUi {
    /* text-align: center; */
    width: 50%;

}

.selectBoxForTable {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;

}

/* th {

    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
} */

.PolicyCounts {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PolicyCounts-Item {
    border-bottom: 1px solid #00dcff;
    padding: 5px;
    font-family: fangsong;
}