.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.card-title-desc {
  max-width: 650px; 
  line-height: 1.5; 
}




.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.Heading {
  background-color: rgb(136, 8, 8);
  border-radius: 7px;
  color: white;
  font-family: initial;
  width: 100%;
  text-align: center;
}



.text-wh-nowrap {
  white-space: nowrap !important
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  /* margin-bottom: 5px; */
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(114, 135, 163);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(35 85 152);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(17, 42, 75, 0.726);
}




/* search box css */
.searchBox {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.5px solid lightgray;
  height: 35px;
  border-radius: 5px;
  padding: 3px;

}

.searchBoxInput {
  border: none;
  outline: none;
  background: transparent;
  width: 200px;


}

/* search box css */

/* display flex with justify Space between  */

.justifySB {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

/* display flex with justify Space between  */


.ProcessBar {
  border-radius: 5px;
  box-shadow: 2px 4px 10px 1px hsla(0, 0%, 79%, .47);
  padding: 8px;
  /* width: 35%; */
}

.cardBgColor {
  background-color: #174379 !important;
}

.MainDiv {
  min-height: 90vh !important;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MainDivPadding {
  /* padding: 160px calc(1.5rem / 2) 30px calc(1.5rem / 2); */
  padding: 40px 10px 10px 10px;
}

.CreateCard {
  margin-bottom: 1.5rem;
  -webkit-box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
  box-shadow: 0 0 13px 0 rgba(236, 236, 241, .44);
  background-color: #ffffff;
  border-radius: 5px;
  /* min-height: 30vh ; */
}


.mybtn {
  background-color: #03254d;
  color: #fff;
}

.mybtn:hover {
  background-color: #1a2e45d7;
  color: #fff;
}

.css-1gkse3u{
  padding: 9px !important;
}