/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"); */

.mybtn {
    background-color: #174379;
    color: #fff;
}

.mybtn:hover {
    background-color: #174379;
    color: #fff;
}


ul {
    list-style: none;
}

a {
    text-decoration: none;
    /* color: inherit; */
}

/* .jodit-react-container ul{
    list-style-type: ;

} */

.jodit-react-container a {
   
    color: black;
}



hr {
    margin: 0.5rem 0;
    opacity: 0.1;
    border: none;
    border-bottom: 1px solid #b6cefc;
}



.sidenav-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    padding: 1.5rem 1.5rem 2rem;
    width: 4.6rem;
    background-color: #1a73e8;
    color: #fff;
    overflow:auto;
    -webkit-transition: width 0.2s ease;
    transition: width 0.2s ease;
    z-index: 10;
}



.nav__brand {
    display: -ms-grid;
    display: grid;
    place-items: center;
    -ms-grid-columns: max-content max-content 1fr;
    grid-template-columns: -webkit-max-content -webkit-max-content 1fr;
    grid-template-columns: max-content max-content 1fr;
    -webkit-column-gap: 1rem;
    column-gap: 1rem;
    -webkit-column-gap: 0.4rem;
    column-gap: 0.4rem;
    padding: 0.75rem 0;
    font-weight: 600;
}

.nav__brand .nav__icon--menu {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: -ms-grid;
    display: grid;
    place-items: center;
    cursor: pointer;
    -webkit-transition: 0.1s ease;
    transition: 0.1s ease;
}

.nav__brand .nav__icon--menu:hover {
    background: #18213a;
}

.nav__brand .nav__icon--menu:active {
    background: #1e2949;
}

.nav__brand-logo {
    cursor: pointer;
}

.nav__item {
    border-radius: 0.5rem;
    -webkit-transition: 0.05s ease;
    transition: 0.05s ease;
}

.nav__item:hover {
    background-color: #244672;
    color: #222;
}

.nav__item:hover a {
    color: #222;
}

.nav__item .nav__drop {
    height: 0px;
    overflow: hidden;
    font-size: 0.875rem;
    -webkit-transition: height 0.2s ease;
    transition: height 0.2s ease;
}

.nav__item .nav__drop a {
    color: #b6cefc;
    -webkit-transition: 0.05s ease;
    transition: 0.05s ease;
}

.nav__item .nav__drop a:hover {
    color: #fff;
}

.nav__item .nav__drop .padding {
    padding: 0 0 0.75rem 3rem;
}

.nav__link {
    display: -ms-grid;
    display: grid;
    place-items: center;
    -ms-grid-columns: max-content max-content 1fr;
    grid-template-columns: -webkit-max-content -webkit-max-content 1fr;
    grid-template-columns: max-content max-content 1fr;
    -webkit-column-gap: 1rem;
    column-gap: 1rem;
    padding: 0.75rem;
    color: #fff;
}

.nav__name {
    font-size: var(--small-font-size);
}

.nav__icon {
    font-size: 1.2rem;
}

.nav__user {
    display: -ms-grid;
    display: grid;
    place-items: center;
    -ms-grid-columns: max-content max-content 1fr;
    grid-template-columns: -webkit-max-content -webkit-max-content 1fr;
    grid-template-columns: max-content max-content 1fr;
    -webkit-column-gap: 1rem;
    column-gap: 1rem;
    -webkit-column-gap: 0.6rem;
    column-gap: 0.6rem;
    margin-top: 0.75rem;
    padding: 0.75rem 1px;
    padding: 0.2rem 0;
    border-radius: 2rem;
    cursor: pointer;
}

.nav__user:hover {
    background: #18213a;
}

.nav__user-image {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    overflow: hidden;
}

.nav__user-image img {
    -webkit-transform: scale(1.5) translate(-1px, 4px);
    transform: scale(1.5) translate(-1px, 4px);
}

.nav__user-info {
    line-height: 1.2rem;
}

.nav__user-info-name {
    font-size: 1rem;
}

.nav__user-info-email {
    font-size: 0.7rem;
    font-weight: 300;
    color: #b6cefc;
}

.toggle-icon {
    justify-self: flex-end;
}

