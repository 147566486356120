@import url(https://fonts.googleapis.com/css?family=Roboto:400,500|Sarabun:400,600,700&amp;display=swap);

#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
}

.navbar-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  height: 70px;
  padding: 0 calc(1.5rem / 2) 0 0;
}

.navbar-header .dropdown .header-item.show {
  background: rgba(52, 58, 64, 0.05);
}

.navbar-brand-box {
  padding: 0 1.5rem;
  text-align: center;
  width: 240px;
}

.logo {
  line-height: 70px;
}

.logo .logo-sm {
  display: inline;
}

.logo-light {
  display: inline;
}

.app-search {
  padding: calc(32px / 2) 0;
}

.app-search .form-control {
  border: none;
  height: 38px;
  padding-left: 20px;
  padding-right: 40px;
  background-color: #e0e0ea;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 30px;
}

.app-search span {
  position: absolute;
  z-index: 10;
  font-size: 13px;
  line-height: 38px;
  right: 13px;
  top: 0;
  color: #525f80;
}

.megamenu-list li {
  position: relative;
  padding: 5px 0;
}

.megamenu-list li a {
  color: #5b626b;
}

@media (max-width: 992px) {
  .navbar-brand-box {
    width: auto;
    position: fixed;
    /* Keep the logo fixed */
    top: 4px;
    /* Adjust the vertical position */
    right: 0;
    /* Position the logo on the right */
    z-index: 1050;
  }

  .logo span.logo-lg {
    display: inline;
  }

  .logo span.logo-sm {
    display: inline-block;
  }
}

.page-content {
  padding: 128px calc(1.5rem / 2) 30px calc(1.5rem / 2);
}

.header-item {
  height: 70px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #525f80;
  border: 0;
  border-radius: 0;
}

.header-item:hover {
  color: #525f80;
}

.header-profile-user {
  height: 32px;
  width: 32px;
  background-color: #191a1b;
}

.logo-dark {
  display: none;
}

.noti-icon i {
  font-size: 24px;
  color: #525f80;
}

.noti-icon .badge {
  position: absolute;
  top: 17px;
  right: 7px;
}

.notification-item .d-flex {
  padding: 0.75rem 1rem;
}

.notification-item .d-flex:hover {
  background-color: #f8f9fa;
}

.dropdown-icon-item {
  display: block;
  border-radius: 1px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: #6c757d;
}

.dropdown-icon-item img {
  height: 24px;
}

.dropdown-icon-item span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-icon-item:hover {
  background-color: #f8f9fa;
}

.app-search .AdminSearchbar {
  background-color: rgba(224, 224, 234, 0.07);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.07);
}

.app-search .AdminSearchbar::placeholder {
  font-size: small;
}

@media (max-width: 600px) {
  .navbar-header .dropdown {
    position: static;
  }

  .navbar-header .dropdown .dropdown-menu {
    left: 10px !important;
    right: 10px !important;
  }
}

/* For screens smaller than 380px */
@media (max-width: 380px) {
  .topnav {
    background: #fff;
  }
}

.topnav .topnav-menu {
  margin: 0;
  padding: 0;
}

.topnav .navbar-nav .nav-link {
  font-size: 14.5px;
  position: relative;
  padding: 1rem 1.3rem;
  color: #fff;
  font-weight: 500 !important;
}

.topnav .navbar-nav .nav-link i {
  font-size: 15px;
  display: inline-block;
}

.topnav .navbar-nav .nav-link:focus,
.topnav .navbar-nav .nav-link:hover {
  color: #ddd;
  background-color: transparent;
}

.topnav {
  background: #333547;
  padding: 0 calc(1.5rem / 2);
  -webkit-box-shadow: 0 0 13px 0 rgba(236, 236, 241, 0.44);
  box-shadow: 0 0 13px 0 rgba(236, 236, 241, 0.44);
  /* margin-top: 70px; */
  position: fixed;
  left: 0;
  right: 0;
  z-index: 100;
}

@media (max-width: 991.98px) {
  .topnav {
    background: #fff;
  }
}

.topnav .topnav-menu {
  margin: 0;
  padding: 0;
}

.topnav .navbar-nav .nav-link {
  font-size: 14.5px;
  position: relative;
  padding: 1rem 1.3rem;
  color: #fff;
  font-weight: 500 !important;
}

.topnav .navbar-nav .nav-link i {
  font-size: 15px;
  display: inline-block;
}

.topnav .navbar-nav .nav-link:focus,
.topnav .navbar-nav .nav-link:hover {
  color: #ddd;
  background-color: transparent;
}

@media (max-width: 991.98px) {
  .topnav .navbar-nav .nav-link:focus,
  .topnav .navbar-nav .nav-link:hover {
    color: #f9f9fa;
  }
}

@media (max-width: 991.98px) {
  .topnav .navbar-nav .nav-link {
    color: #f8f8f8;
  }
}

.topnav .navbar-nav > .dropdown.active > a {
  /* color: #174379 !important; */
  color: #fa812f !important;
}

@media (max-width: 991.98px) {
  .topnav .navbar-nav > .dropdown.active > a {
    /* color: #174379 !important; */
    color: #fa812f !important;
  }
}

.topnav .navbar-nav .dropdown-item {
  color: #66676d;
}

.topnav .navbar-nav .dropdown-item.active,
.topnav .navbar-nav .dropdown-item:hover {
  /* color: #174379; */
  color: #fa812f;
  background-color: transparent;
}

.topnav .navbar-nav .nav-item .nav-link.active {
  /* color: #174379; */
  color: #fa812f;
}

@media (max-width: 991.98px) {
  .topnav .navbar-nav .nav-item .nav-link.active {
    /* color: #174379 !important; */
    color: #fa812f !important;
  }
}

.topnav .navbar-nav .dropdown.active > a {
  color: #fa812f;
  background-color: transparent;
}

@media (min-width: 992px) {
  .navbar.navbar-light.navbar-expand-lg.topnav-menu {
    padding-left: 0;
    /* Remove any padding from the navbar itself */
  }

  .container,
  .container-fluid {
    padding-left: 0;
    /* Remove Bootstrap's default container padding */
  }

  .navbar-brand-box {
    margin-left: 0 !important;
    /* Forcefully remove margin */
    padding-left: 0 !important;
    /* Forcefully remove padding */
   
  }

  .navbar-collapse {
    justify-content: flex-end;
    width: 100%;
  }

  .navbar-nav {
    display: flex;
    align-items: center;
  }

  .nav-link {
    padding: 10px 15px;
    /* Space between menu items */
  }

  /* Hide hamburger icon */
  .navbar-toggler {
    display: none;
  }
  .navbar-nav .dropdown:hover .dropdown-menu {
    display: block; 
    visibility: visible;
    opacity: 1;
  }

  .navbar-nav .dropdown-menu {
    display: none; 
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out; 
    position: absolute;
  }
}

@media (max-width: 1199.98px) {
  .topnav-menu .navbar-nav li:last-of-type .dropdown .dropdown-menu {
    right: 100%;
    left: auto;
  }
}

@media (max-width: 991.98px) {
  .topnav {
    max-height: 360px;
    overflow-y: auto;
    padding: 0;
  }

  .topnav .navbar-nav .nav-link {
    padding: 0.75rem 1.1rem;
  }

  .topnav .dropdown .dropdown-menu {
    background-color: transparent;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding-left: 15px;
  }

  .topnav .dropdown .dropdown-menu.mega-dropdown-menu {
    width: auto;
  }

  .topnav .dropdown .dropdown-menu.mega-dropdown-menu .row {
    margin: 0;
  }

  .topnav .dropdown .dropdown-item {
    position: relative;
    background-color: transparent;
  }

  .topnav .dropdown .dropdown-item.active,
  .topnav .dropdown .dropdown-item:active {
    /* color: #174379; */
    color: #fa812f;
  }

  .topnav .arrow-down::after {
    right: 15px;
    position: absolute;
  }
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

@media screen and (min-width: 990px) {
  .HideInDesktop {
    display: none;
  }
}

@media screen and (max-width: 990px) {
  .HideInPhone {
    display: none;
  }
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
