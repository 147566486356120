body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth;
}

.loader {
  border: 2px solid transparent;
  border-radius: 50%;
  border-top: 2px solid #3498db; /* Change this color as needed */
  width: 3px; /* Adjust size */
  height: 3px; /* Adjust size */
  animation: spin 0.6s linear infinite;
  margin-right: 8px; /* Space between loader and text */
  display: inline-block; /* Ensure it's inline-block */
  vertical-align: middle; /* Align vertically */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ActiveLink {
  color: #172466;
  border-bottom: 2px solid red;
}

.purchaseVoucherTable {
  display: flex;
  justify-content: space-around;
}

.SelectBoxWith {
  width: 200px;
}
/* 
img:before {
  content: ' ';
  display: block;
  position: absolute;
  height: 100px;
  background-repeat: no-repeat;
  width: 100px;
 
  background-image: url(https://placehold.co/150x150/png);
} */

label[required]::after {
  content: "*";
  color: red;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::placeholder {
  font: sans-serif;
}

.searchFilter {
  background-color: rgba(218, 218, 221, 0.07);
  color: #100f0f;
  border: 1px solid rgba(29, 2, 2, 0.07);
}

.searchFilter-box {
  position: relative;
}

.searchFilter-box .form-control {
  /* border: none; */
  width: 200px;
  height: 38px;
  padding-left: 8px;
  padding-right: 40px;
  /* background-color: #f5f5f6; */
  box-shadow: none;
  border-radius: 4px;
}

.searchFilter-box .form-control::placeholder {
  font-size: 18px;
}

.searchFilter-box .span-Icon {
  position: absolute;
  z-index: 10;
  font-size: 20px;
  line-height: 38px;
  right: 13px;
  top: 10px;
  color: #525f80;
}

.css-11yukd5-MuiTabs-indicator {
  position: absolute;
  height: 1px !important;
  bottom: 18px !important;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #9c27b0;
}

.css-b62m3t-container {
  min-width: 190px !important;
}

.CategoryButton {
  width: 100%;
  text-align: center;
  margin: 4px;
  padding: 5px;
  /* border: 1px solid gray; */
  background: #082ac9;
  /* color: white !important; */
  border-radius: 5px;
  transition: all ease-out 0.1s;
}

.isReportFiled div {
  margin: 1rem;
}
