.table-input tr td input{
 width: 140px;
}


.table-input tr td input::-webkit-outer-spin-button,
.table-input tr td input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.closedIcon {
  position: absolute;
  right: -14px;
  top: -14px;
  background-color: white;
  border: 1px solid black;
  border-radius: 50%;
  cursor: pointer;
  font-size: 25px;
  transition: transform 0.4s;
}
.closedIcon:hover {
  transform: scale(1.2);
}

.css-1gkse3u {
  border: none !important;
}

.imgDisplay:before {
  content: " ";
  display: block;
  position: absolute;
  height:300px;
  background-repeat: no-repeat;
  width: 100%;
  background-image: url(../utils/UiImages/FileModalDefultImage.png);
  /* background-image: url(https://placehold.co/400x400/png); */
}


#searchBox-container {
  display: flex;
  align-items: center;
}

#text-box {
  display: none;
  margin-left: 10px;
}

#input-box {
  width: 200px;
  padding: 5px;
  border: 1px solid #ccc;
}

#combo-box {
  padding: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
}