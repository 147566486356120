.Adminfooter {
  /* bottom: 0; */
  padding: 20px calc(1.5rem / 2);
  position: absolute;
  right: 0;
  text-align: center;
  color: #74788d;
  left: 240px;
  height: 60px;
  background-color: #f2f2f5;
}
@media (max-width: 992px) {
  .Adminfooter {
    left: 0;
  }
}
.vertical-collpsed .Adminfooter {
  left: 70px;
}
.Adminfooter {
  left: 0 !important;
}
