.login-container {
  /* background-image: linear-gradient(rgba(175, 96, 51, 0.29),rgba(175, 96, 51, 0.436)),url("./loginImg.jpg"); */
  /* background-size: cover; */
  /* background-color: #e8e8e8; */

  font-size: 14px;
}

.form-container-login {
  min-width: 400px;
  max-width: 500px;
  background-color: #fff;
  padding: 32px 24px;
  font-size: 14px;
  font-family: inherit;
  color: #212121;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 7px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15), 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.form-container-login button:active {
  scale: 0.95;
}

.form-container-login .logo-container {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
}

.form-container-login .form {
  display: flex;
  flex-direction: column;
}

.form-container-login .form-group-login {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.Login-label {
  display: block;
  margin-bottom: 5px;
}

.login-PhoneNumber {
  width: 100%;
  padding: 12px 16px;
  border-radius: 6px;
  font-family: inherit;
  border: 1px solid #ccc;
}

.login-PhoneNumber::placeholder {
  opacity: 0.5;
}

.login-PhoneNumber:focus {
  outline: none;
  border-color: #1778f2;
}

.form-container-login .form-submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  color: #fff;
  /* background-color: #212121; */
  background-color: #174379;
  border: none;
  width: 100%;
  padding: 12px 16px;
  font-size: inherit;
  gap: 8px;
  margin: 12px 0;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
}

.form-container-login .form-submit-btn:hover {
  /* background-color: #313131; */
  background-color: #073a79;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3), 0px 4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

/* Verify Button Styles */
.form-container-login .form-submit-btn.verify {
  background-color: #174379; /* Primary color */
}

.form-container-login .form-submit-btn.verify:hover {
  background-color: #073a79; /* Darker shade for hover */
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3), 0px 4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* Lift effect */
}

/* Back Button Styles */
.form-container-login .form-submit-btn.back {
  background-color: #ffc107;
  /* color: #212121; */
}

.form-container-login .form-submit-btn.back:hover {
  background-color: #e0a800;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2), 0px 3px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.form-container-login .link {
  color: #1778f2;
  text-decoration: none;
}

.form-container-login .signup-link {
  align-self: center;
  font-weight: 500;
}

.form-container-login .signup-link .link {
  font-weight: 400;
}

.form-container-login .link:hover {
  text-decoration: underline;
}

.Otp-send-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.form-otp {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 400px;
  background-color: white;
  border-radius: 12px;
  padding: 20px;
}

.title-otp {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.message-otp {
  color: #a3a3a3;
  font-size: 14px;
  /* margin-top: 4px; */
  margin: 0px;
  text-align: start;
}

.inputs-otp {
  margin-top: 10px;
}

.input-otp {
  width: 32px;
  height: 32px;
  text-align: center;
  border: none;
  border-bottom: 1.5px solid #d2d2d2;
  margin: 0 10px;
}

.input-otp:focus {
  border-bottom: 1.5px solid royalblue;
  outline: none;
}

.action-otp {
  margin-top: 24px;
  padding: 12px 16px;
  border-radius: 8px;
  border: none;
  background-color: royalblue;
  color: white;
  cursor: pointer;
  align-self: end;
}

@keyframes slideUp {
  0% {
    transform: translateY(100vh); /* Start from below the viewport */
    opacity: 0; /* Initially invisible */
  }
  100% {
    transform: translateY(0); /* Final position */
    opacity: 1; /* Fully visible */
  }
}
