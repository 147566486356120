.PlusBox {
    cursor: pointer;
    position: relative;
  }
  
  #FileDisplay {
    display: none;
  }
  #FileDisplay1 {
    display: none;
  }
  #FileDisplay2 {
    display: none;
  }
  #FileDisplay3 {
    display: none;
  }
  #FileDisplay4 {
    display: none;
  }
  #FileDisplay5 {
    display: none;
  }
  #FileDisplay6 {
    display: none;
  }
  #FileDisplay7 {
    display: none;
  }
  #FileDisplay8 {
    display: none;
  }
  #FileDisplay9 {
    display: none;
  }
  #FileDisplay10 {
    display: none;
  }
  #FileDisplay11 {
    display: none;
  }
  
  .Edit-image {
    display: none;
    position: absolute;
    border-radius: 10em;
    padding: 2px;
    text-decoration: none;
    background: #555;
    border: 3px solid #fff;
    color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    -webkit-transition: background 0.5s;
    transition: background 0.5s;
    text-decoration: none;
    width: 30px;
    height: 30px;
  }
  
  .Edit-image:hover {
    background: green;
    cursor: pointer;
  }
  
  .Delete-image {
    display: none;
    position: absolute;
    /* top: 180px;
    left: 115px; */
    border-radius: 10em;
    padding: 2px;
    text-decoration: none;
    /* font: 700 21px/20px sans-serif; */
    background: #555;
    border: 3px solid #fff;
    color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    -webkit-transition: background 0.5s;
    transition: background 0.5s;
    text-decoration: none;
    width: 30px;
    height: 30px;
  }
  
  .Delete-image:hover {
    background: red;
    cursor: pointer;
  }
  .Document_Upload {
    display: flex;
    gap: 40px;
    border: 1px solid gray;
    padding: 11px;
    border-radius: 5px;
    box-shadow: 1px 1px 2px black;
    height: 220px;
  }
  
  .UrlData {
    opacity: 1;
  }
  
  .UrlData:hover {
    opacity: 0.3;
    cursor: zoom-in;
  }
  
  .UrlData:before {
    content: " ";
    display: block;
    position: absolute;
    height: 150px;
    /* background-repeat: no-repeat; */
    width: 150px;
    background-image: url(../utils/UiImages/defultImage.png);
    /* background-image: url(https://placehold.co/150x150/png); */
  }
  