.pos-bill-com-box p {
    margin: 0;

}

.Heading {
    background-color: green;
    color: white;
    display: flex;
    height: 2.5rem;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.pos-details {
    padding: 1rem;
    gap: 10px;
    display: flex;
    justify-content : center;
    min-height: 20vh;
}

.pos-address {
    border: 1px solid gray;
    width: 40%;
    padding: 1rem;
}

.pos-account {
    border: 1px solid gray;
    width: 60%;
    padding: 1rem
}

.pos-account-data{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.table-Div{
    padding-top: 1rem
}

.pos-bill{
    /* padding: .5rem;
    gap: 10px; */
    display: flex;
    justify-content: center;
    min-height: 20vh;
    padding: 1rem;
}

.pos-bill-data1 {
   
    width: 40%;
    padding: 1rem;
}

.table-Div ul  {
    list-style:circle;
}


/* Style for the table */
.styled-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

/* Style for table headers (th) */
.styled-table th {
    background-color: #007BFF; 
    color: rgb(22, 22, 22); /* Header text color */
    font-weight: bold; /* Bold font for header text */
    padding: 10px; /* Padding around header text */
    text-align: left; /* Align header text to the left */
}

/* Style for table cells (td) */
.styled-table td {
    border: 1px solid #ccc; /* Border around cells */
    padding: 8px; /* Padding around cell content */
    text-align: left; /* Align cell content to the left */
}

/* Style for alternate rows (optional) */
.styled-table tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Background color for even rows */
}
